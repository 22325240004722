import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import GraphState from './graph.reducer';

export const selectGraph = createFeatureSelector< GraphState>('graph');

export const selectGraphByAccessionCode = (accessionCode: string) => createSelector(
  selectGraph,
  (state) => state.dictionary[accessionCode]
);
