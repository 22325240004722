<a [href]="href" class="download-link" download>
  <button
    class="download-button"
    type="button"
    [id]="buttonId"
    mat-stroked-button
    color="basic"
  >
    <mat-icon class="download-icon">save_alt</mat-icon>
    {{text}}
  </button>
</a>
