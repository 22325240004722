import { Pipe, PipeTransform } from '@angular/core';
import { SimpleIndexKit } from '../../models/indexKit';

@Pipe({
  name: 'indexKitName'
})
export class IndexKitNamePipe implements PipeTransform {

  transform(kit: SimpleIndexKit, args?: any): any {
    return `${kit.barcode} (${kit.indexKitModel})`;
  }
}
