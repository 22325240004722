import { Component, Input, OnInit } from '@angular/core';
import { PoolWithRequest } from '../../models/pool';

@Component({
  selector: 'nemo-pool-table',
  template: `
      <table mat-table [dataSource]="pool?.samples">
          <ng-container matColumnDef="request">
              <th mat-header-cell *matHeaderCellDef>Request</th>
              <td mat-cell *matCellDef="let sample"
                  routerLink="/request/{{sample.requestAccessionCode}}"
                  class="clickable">
                  {{sample.requestAccessionCode | accessionCode}}
              </td>
          </ng-container>
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let sample">{{sample.name}}</td>
          </ng-container>
          <ng-container matColumnDef="accession_code">
              <th mat-header-cell *matHeaderCellDef>Accession code</th>
              <td mat-cell *matCellDef="let sample">{{sample.accessionCode | accessionCode}}</td>
          </ng-container>
          <ng-container matColumnDef="index_kit">
              <th mat-header-cell *matHeaderCellDef>Index kit</th>
              <td mat-cell *matCellDef="let sample">{{sample.assignedIndex?.kit?.barcode}}</td>
          </ng-container>
          <ng-container matColumnDef="i5">
              <th mat-header-cell *matHeaderCellDef>I5</th>
              <td mat-cell *matCellDef="let sample"
                  class="index-sequence">
                  {{sample.assignedIndex | i5}}
              </td>
          </ng-container>
          <ng-container matColumnDef="i7">
              <th mat-header-cell *matHeaderCellDef>I7</th>
              <td mat-cell *matCellDef="let sample"
                  class="index-sequence">
                  {{sample.assignedIndex | i7}}
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  `,
  styles: [`
      table {
          width: 100%;
          margin-top: 20px;
      }
  `]
})
export class PoolTableComponent {
  @Input() pool: PoolWithRequest;
  displayedColumns = ['request', 'name', 'accession_code', 'index_kit', 'i5', 'i7'];
}
