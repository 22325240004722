import { Component, Input } from '@angular/core';

/**
 * Presentational button that downloads the file located at `href`.
 * @field buttonId: the id of the button
 * @field text: the text inside the button
 * @field href: the URL to the file
 */
@Component({
  selector: 'nemo-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent {

  @Input() buttonId: string;
  @Input() text: string;
  @Input() href: string;

}
