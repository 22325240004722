import { Component, Input } from '@angular/core';
import { Task } from '../../models/task.model';

@Component({
  selector: 'nemo-tasks-list',
  template: `
    <h3>Task trail</h3>
    <nemo-tasks-list-item *ngFor="let task of tasks" [task]="task"></nemo-tasks-list-item>
  `
})
export class TasksListComponent {
  @Input() tasks: Task[];
}
