import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Graph } from '../../../models/graph';
import * as d3 from 'd3';
import { formatEntityRoute } from '../../../../global-context/shared/pipes/entity-routes.pipe';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';

@Component({
  selector: 'nemo-graph-dependency',
  template: `
      <div class="layout" #layout></div>`,
  styles: [
    `
              line {
                  stroke: blue;
              }
    
              g.node circle {
                  stroke: green;
                  stroke-width: 3px;
                  fill: grey;
    
              }
    
              g.node text {
                  text-anchor: middle;
                  alignment-baseline: middle;
              }
    
              g.node rect {
                  fill: white;
                  stroke: darkgrey;
                  stroke-width: 1;
              }
    
              g.node.root rect {
                  fill: white;
                  stroke: orangered;
                  stroke-width: 2;
              }
          `
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GraphDependencyComponent implements AfterContentInit, OnChanges {
  @Input() graph: Graph;
  @Input() rootAccessionCode: string;
  @ViewChild('layout', {static: true}) layoutDiv: ElementRef;

  private svg: any;
  private height = 400;
  private width = 800;

  constructor(
    private readonly elementRef: ElementRef,
  ) {
  }

  ngAfterContentInit(): void {

    this.svg = d3.select(this.layoutDiv.nativeElement)
      .append('svg')
      .attr('height', this.height)
      .attr('width', '100%');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.graph && this.rootAccessionCode) {
      this.setupGraph();
    }
  }

  setupGraph() {
    const renderedGraph = this.anyfy(this.graph);

    const rootNode = renderedGraph.vertices.find((v) => v.id === this.rootAccessionCode);
    rootNode.fixed = true;
    rootNode.x = this.width / 2;
    rootNode.y = 50;

    this.svg.selectAll('g').remove();
    const container = this.svg.append('g');

    this.svg.call(
      d3.zoom()
        .scaleExtent([.1, 4])
        .on('zoom', function (event) {
          container.attr('transform', event.transform);
        })
    );

    const simulation = d3.forceSimulation(renderedGraph.vertices)
      .force('link',
        d3.forceLink(renderedGraph.edges)
          .id(d => d['id'])
      )
      .force('charge',
        d3.forceManyBody()
          .strength(-2000)
      )
      .force('center', d3.forceCenter(this.width / 2, this.height / 2));


    const link = container
      .append('g')
      .classed('links', true)
      .selectAll('line')
      .data(renderedGraph.edges)
      .join('line')
      .classed('line', true);

    const node = container
      .append('g')
      .classed('nodes', true)
      .selectAll('g.node')
      .data(renderedGraph.vertices)
      .enter()
      .append('g')
      .classed('node', true)
      .classed('root', (v) => v.id === this.rootAccessionCode);

    node.append('rect');
    const displayFlowCellId =
      (id) => (id.length < 12) ? id : `${id.substr(0, 4)}...${id.substr(id.length - 4)}`;
    node.append('a')
      .attr('href', (vertex) => formatEntityRoute(vertex.id, vertex.type))
      .append('text')
      .text((vertex) => (vertex.type === 'FlowCell') ?
                        displayFlowCellId(vertex.id) :
                        formatAccessionCode(vertex.id));

    node.selectAll('text').each(function (v) {
      v.bbox = this.getBBox();
    });
    const rectPadding = 4;
    node.selectAll('rect')
      .attr('height', (d) => d.bbox.height + 2 * rectPadding)
      .attr('width', (d) => d.bbox.width + 2 * rectPadding)
      .attr('x', (d) => -d.bbox.width / 2 - rectPadding)
      .attr('y', (d) => -d.bbox.height / 2 - rectPadding)
    ;

    simulation
      .on('tick', ticked);

    function ticked() {
      link
        .attr('x1', function (d) {
          return d.source.x;
        })
        .attr('y1', function (d) {
          return d.source.y;
        })
        .attr('x2', function (d) {
          return d.target.x;
        })
        .attr('y2', function (d) {
          return d.target.y;
        });
      node
        .attr('transform', d => `translate(${d.x}, ${d.y})`);
    }

  }

  anyfy(g: Graph): { vertices: any[], edges: any[] } {
    return {
      vertices: g.vertices.map(v => ({
        id: v.entityAccessionCode,
        type: v.type
      })),
      edges: g.edges.map(({from, to, type}) => ({
        source: from.entityAccessionCode,
        target: to.entityAccessionCode,
        type
      }))
    };
  }
}
