import { Pipe, PipeTransform } from '@angular/core';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import { DomSanitizer } from '@angular/platform-browser';


export function formatRequestRoute(accessionCode: string) {
  return `/request/${accessionCode}`;
}

export function formatPoolRoute(accessionCode: string) {
  return `/pool/${accessionCode}`;
}

export function formatPlateRoute(accessionCode: string) {
  return `/plate/${accessionCode}`;
}

export function formatFlowCellRoute(accessionCode: string) {
  return `/flow-cell/${accessionCode}`;
}

export function formatEntityRoute(accessionCode: string, type: string): string {
  switch (type.toLowerCase()) {
    case 'genomicsrequest': {
      return formatRequestRoute(accessionCode);
      break;
    }
    case 'flowcell': {
      return formatFlowCellRoute(accessionCode);
      break;
    }
    case 'plate': {
      return formatPlateRoute(accessionCode);
      break;
    }
    case 'pool': {
      return formatPoolRoute(accessionCode);
      break;
    }
    default: {
      throw new Error(`Cannot infer entity route for ${accessionCode} of type [${type}]`);
    }
  }
}

export function formatEntityLink(accessionCode: string, type: string): string {
  const uri = formatEntityRoute(accessionCode, type);
  const displayedAC = (type.toLowerCase() === 'flowcell') ? accessionCode : formatAccessionCode(accessionCode);
  return `<a href="${uri}">${displayedAC}</a>`;
}


@Pipe({
  name: 'entityRoute'
})
export class EntityRoutesPipe implements PipeTransform {
  transform(value: any, type: string): any {
    return formatEntityRoute(value, type);
  }
}

@Pipe({
  name: 'requestRoute'
})
export class RequestRoutePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return formatRequestRoute(value);
  }
}

@Pipe({
  name: 'plateRoute'
})
export class PlateRoutePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return formatPlateRoute(value);
  }
}

@Pipe({
  name: 'flowCellRoute'
})
export class FlowCellRoutePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return formatFlowCellRoute(value);
  }
}

@Pipe({
  name: 'entityLink'
})
export class EntityRoutePipe implements PipeTransform {
  constructor(
    private domSanitizer: DomSanitizer
  ) {
  }

  transform(value: any, type: string): any {
    const html = formatEntityLink(value, type);
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
