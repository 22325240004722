import { Component, Input } from '@angular/core';
import { Task } from '../../models/task.model';

@Component({
  selector: 'nemo-tasks-list-item',
  template: `
    <div class="user-image-container">
      <nemo-user-image [user]="task.user"></nemo-user-image>
    </div>

    <div>
      <p class="task-info">
        <strong>{{task.user.name}} </strong>
        {{task.action | taskType}}
        <span *ngIf="task.payload.flowCellId"> on flow cell
          <strong class="clickable"
                  routerLink="/flow-cell/{{task.payload.flowCellId}}"> {{task.payload.flowCellId}} </strong>
        </span>
        <span *ngIf="task.payload.plateAccessionCode"> plate
          <strong class="clickable"
                  routerLink="/plate/{{task.payload.plateAccessionCode}}">
            {{task.payload.plateAccessionCode | accessionCode}}
          </strong>
        </span>

        <span *ngIf="task.payload.sourcePlateAccessionCode">
          from plate<strong class="accession-code"> {{task.payload.sourcePlateAccessionCode | accessionCode}} </strong>
        </span>

        <span *ngIf="task.payload.targetPlateAccessionCode">
          onto plate<strong class="accession-code"> {{task.payload.targetPlateAccessionCode | accessionCode}} </strong>
        </span>

        impacting request(s)
        <nemo-request-accession-code-list
          [accessionCodes]="task.requestsAccessionCode"></nemo-request-accession-code-list>
      </p>
      <span class="date">{{task.date | date : "medium"}}</span>
    </div>
  `,
  styles: [`
    :host {
      border-bottom: 1px solid #dadce0;
      display: flex;
    }

    .task-info {
      margin-bottom: 4px;
      margin-top: 6px;
    }

    .date {
      color: #5f6368;
      margin-top: 0;
    }

    .user-image-container {
      height: inherit;
      line-height: 48px;
      align-items: center;
      display: flex;
    }
  `]
})
export class TasksListItemComponent {
  @Input() task: Task;
}
