import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { CsvData, CsvWriterService } from '../../../core/services/csv-writer.service';
import { Plate96 } from '../../../labware/models/plate-96';
import {
  getAllPoolsByRequestsAccessionCode,
  getAllRequestsAccessionCode
} from '../../../labware/services/plate.service';
import { Pool } from '../../../bio/models/pool';
import { getExportFilename } from '../services/export.services';

@Component({
  selector: 'nemo-select-export-button',
  template: `
    <mat-form-field>
      <mat-label>Export to CSV</mat-label>
      <mat-select #selectRequester>
        <mat-option (click)="exportToCsv(null)">All requests</mat-option>
        <mat-option *ngFor="let accessionCode of requests"
                    (click)="exportToCsv(accessionCode)">
          {{accessionCode| accessionCode}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class ExportSelectButtonComponent implements OnChanges {

  @ViewChild('selectRequester', {static: true}) selectRequester: MatSelect;
  @Input() plate: Plate96<Pool>;
  @Input() tableName: string;
  @Input() tableColumns: string[];
  @Input() formatData: () => any;
  requests: string[];

  constructor(
    private readonly csvWriter: CsvWriterService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plate) {
      if (this.plate) {
        this.requests = getAllRequestsAccessionCode(this.plate);
      }
    }
  }

  exportToCsv(requestAccessionCode: string) {
    if (this.selectRequester) {
      this.selectRequester.value = null;
    }
    const filename = getExportFilename(this.tableName, this.plate.accessionCode, requestAccessionCode);
    const data = getAllPoolsByRequestsAccessionCode(this.plate, requestAccessionCode).map(this.formatData);
    const meta = {columnNames: this.tableColumns, task: this.tableName};
    const csvData: CsvData = {meta, data};
    this.csvWriter.downloadAsCsv(csvData, filename);
  }
}
