import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { findGraphByEntityAccessionCodeFromPage } from '../../../store';
import { Observable } from 'rxjs';
import { Graph } from '../../../models/graph';
import { selectGraphByAccessionCode } from '../../../store/graph.selectors';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nemo-graph-dependency-container',
  template: `
      <nemo-graph-dependency
              [graph]="graph$ |async"
              [rootAccessionCode]="rootAccessionCode"
      ></nemo-graph-dependency>
  `,
  styles: []
})
export class GraphDependencyContainerComponent implements OnChanges {
  @Input() rootAccessionCode: string;
  graph$: Observable<Graph>;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rootAccessionCode'].currentValue) {
      this.store.dispatch(findGraphByEntityAccessionCodeFromPage({accessionCode: this.rootAccessionCode}));
      this.graph$ = this.store.select(selectGraphByAccessionCode(this.rootAccessionCode)).pipe(
        
        filter(g => Boolean(g))
      );
    }
  }

}
