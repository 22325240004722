import { Pipe, PipeTransform } from '@angular/core';
import { Index, TerminusTypes } from '../../../bio/models/barcode-index';

@Pipe({
  name: 'i7'
})
export class I7Pipe implements PipeTransform {

  transform(index: Index): string {
    if (index) {
      return index.sequences
        .filter(seq => seq.terminus === TerminusTypes.I7)
        .map(seq => seq.sequence)
        .join(';');
    }
  }
}
