// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: window.location.origin,
  apiUrl: '/api',
  auth: {
    clientId: '519041030625-fsd4kqnl6smf6osiamo17nu185ad9mja.apps.googleusercontent.com',
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid profile email',
    silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
    showDebugInformation: true,
    timeoutFactor: 0.5
  },
  doc: {
    howToSubmitter: 'https://docs.google.com/document/d/1ntFxDPHB9ok3BkvKobo1my_g0jybD9fXhelGWevAYSo'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
